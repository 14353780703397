'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var mapList = false;
var universel = void 0;

$(document).ready(function () {
    Config.template = 'UNIVERSEL';
    universel = new Universel();
});

var Universel = function () {
    function Universel() {
        _classCallCheck(this, Universel);

        this.$scope = {};
        this.map = $('.map');
        this.more = $('.more');
        this.engine = $('.engine');
        this.pageDim = $('.page-dim');
        this.pubHome = $('#campaign-home');
        this.searchBudgetMin = $('input#property_search_budgetMin');
        this.searchBudgetMax = $('input#property_search_budgetMax');
        this.searchBudgetMin1 = $('input#property_search_budgetMin1');
        this.searchBudgetMax1 = $('input#property_search_budgetMax1');
        this.searchSurfaceMin = $('input#property_search_surfaceMin');
        this.searchPropertyRef = $('input#property_search_ref');
        this.TRIGGERS = [this.searchBudgetMin, this.searchBudgetMax, this.searchBudgetMin1, this.searchBudgetMax1, this.searchSurfaceMin, this.searchPropertyRef];
        this.triMenu = $('select.tri-format');
        this.to = 0;
        this.yieldTo = 0;
        this.lastScrollTop = 0;
        this.delta = 5;
        this.navbarHeight = $('header').outerHeight();
        this.placeholderVille = 'Choisissez une ville / un département';
        this.placeholderType = 'Choisissez un type de bien';
        this.initSelect();
        this.initPage($(document.getElementById('app')).css('display'), $(document.getElementById('hn-fix')).css('display'));
    }

    _createClass(Universel, [{
        key: 'initPage',
        value: function initPage(app, h5Fix) {
            var _this = this;

            if (app || h5Fix) {
                $('h5:not(.h6)').remove();
            }
            if (app) {
                this.$scope = angular.element('#app').scope();
                this.map.css('display') ? this.initMap() : null;
                this.prepareMotor();
                this.initIndicateurs();
                this.bindUIActions();
            } else {
                //Home page
                if (this.pubHome.css('display')) {
                    var img = new Image();
                    img.onload = function () {
                        if (BrowserInfo.isMobile) _this.resizePub();
                        _this.pubHome.css({
                            'margin-top': '-' + (_this.pubHome.height() / 2 + 1) + 'px',
                            'display': 'block'
                        });
                        _this.pubHome.removeClass('hidden');
                    };
                    img.src = this.pubHome.find('img').attr('src');
                }
                //List page
                $('.more-small').parent().css('display', 'none');
            }
            this.yieldTo = setTimeout(this.validateSelect, 0);
            this.checkPageOffset();
            window.addEventListener('scroll', function () {
                _this.checkPageOffset();
            });
            window.onresize = function () {
                if (window.innerWidth > 1025) {
                    $('.off-canvas-wrap').removeClass('move-right move-left');
                }
            };
        }
    }, {
        key: 'bindUIActions',
        value: function bindUIActions() {
            var _this2 = this;

            $("#submit-search-st3").on("click", function (e) {
                e.preventDefault();
                $("#form-st3").submit();
            });
            $('#search').on("click", function (e) {
                e.preventDefault();
                _this2.updateSelect2([$("select.villemoteur"), $("select.typemoteur")], [$("select.villemoteur1").val(), $("select.typemoteur1").val()]);
                $("#submit").trigger('click');
                _this2.resetData();
            });
            $('.clear-filters').on("click", function (e) {
                e.preventDefault();
                _this2.resetForm();
            });
            this.more.on("click", function () {
                _this2.prepToggle(true);
            });
            $('.close-menu, .annuler, .page-dim').on("click", function () {
                _this2.prepToggle(false);
            });
            this.triMenu.on('change', function () {
                window.open(this.options[this.selectedIndex].value, '_self');
            });
        }
    }, {
        key: 'resizePub',
        value: function resizePub() {
            $('.ui-ad-image').css('max-height', '90vh');
            $('#close-ad').css({
                'right': '-13px',
                'top': '-13px',
                'width': '25px',
                'height': '25px'
            });
            $('#close-ad i').css({
                'font-size': '1em',
                'top': '0'
            });
        }
    }, {
        key: 'resetData',
        value: function resetData() {
            this.$scope.data.nbRoom = null;
            this.$scope.data.nbBedRoom = null;
            this.$scope.data.surfaceMin = null;
            this.$scope.data.ref = null;
            $('#property_search_nbRoom').prop('selectedIndex', 0);
            $('#property_search_nbBedRoom').prop('selectedIndex', 0);
            this.searchSurfaceMin.val('');
            this.searchPropertyRef.val('');
        }
    }, {
        key: 'initIndicateurs',
        value: function initIndicateurs() {
            var _this3 = this;

            $('input#property_search_budgetMin').blur(function () {
                $("input#property_search_budgetMin1").val($(this).val());
            });
            $('input#property_search_budgetMax').blur(function () {
                $("input#property_search_budgetMax1").val($(this).val());
            });
            this.searchBudgetMin1.blur(function () {
                $("input#property_search_budgetMin").val($(this).val());
            });
            this.searchBudgetMax1.blur(function () {
                $("input#property_search_budgetMax").val($(this).val());
            });
            this.searchSurfaceMin.blur(function () {
                _this3.updateIndicators();
            });
            this.searchPropertyRef.blur(function () {
                _this3.updateIndicators();
            });
            $('#property_search_nbRoom, #property_search_nbBedRoom, #property_search_nbBedding, #property_search_beginAt, #property_search_beginAt').change(this.updateIndicators);
        }
    }, {
        key: 'prepareMotor',
        value: function prepareMotor() {
            var _this4 = this;

            $("select.villemoteur1").append($('select.villemoteur').html());
            $("select.typemoteur > option").each(function () {
                $("select.typemoteur1").append($("<option></option>").attr("value", $(this).val()).text($(this).text()));
            });
            $('div.villemoteur1, div.typemoteur1').css('display', 'block');
            $('.more-small').on("click", function () {
                _this4.updateSelect2([$("select.villemoteur"), $("select.typemoteur")], [$("select.villemoteur1").val(), $("select.typemoteur1").val()]);
                _this4.toggleEngine();
            });
        }
    }, {
        key: 'initSelect',
        value: function initSelect() {
            var SELECTS = [{ 'select': $("select.villemoteur"), 'placeholder': this.placeholderVille }, { 'select': $("select.villemoteur1"), 'placeholder': this.placeholderVille }, { 'select': $("select.moteurlightvillebien"), 'placeholder': this.placeholderVille }, { 'select': $("select.typemoteur"), 'placeholder': this.placeholderType }, { 'select': $("select.typemoteur1"), 'placeholder': this.placeholderType }],
                i;
            for (i = 0; i < SELECTS.length; i++) {
                SELECTS[i]['select'].select2({
                    placeholder: SELECTS[i]['placeholder'],
                    dropdownCssClass: "bigdrop",
                    allowClear: true
                });
            }
        }
    }, {
        key: 'checkPageOffset',
        value: function checkPageOffset() {
            this.to ? clearTimeout(this.to) : null;
            var listPage = $('#app').css('display') || $('.new-program-search').css('display');
            var offset = listPage ? parseInt($('.selec-universel.header-interne').css('height')) - 40 : parseInt($('.top-bar-section').css('height'));
            if (typeof this.setStickyOffset === 'function') {
                this.setStickyOffset(window.pageYOffset > offset, listPage);
            }
            this.detectScroll();
        }
    }, {
        key: 'setStickyOffset',
        value: function setStickyOffset(up, listPage) {
            if (listPage) {
                var unit = up ? '130px' : '0';
                $('.map').css('margin-top', unit);
                if (!mapList) {
                    $('.properties').css('margin-top', unit);
                }
                if (up) {
                    $('.sticky-top').css({
                        'position': 'fixed',
                        'top': '55px',
                        'z-index': '10',
                        'width': '100%'
                    });
                } else {
                    $('.sticky-top').css({
                        'position': 'relative',
                        'top': '0',
                        'z-index': 'intitial',
                        'width': 'initial'
                    });
                }
            }
        }
    }, {
        key: 'detectScroll',
        value: function detectScroll() {
            var st = BrowserInfo.isSafari ? $('body').scrollTop() : $('html, body').scrollTop();
            st = BrowserInfo.isEdge ? $('body').scrollTop() : st;
            if (Math.abs(this.lastScrollTop - st) <= this.delta) return;
            if (st > this.lastScrollTop && st > this.navbarHeight) {
                $('header').removeClass('nav-down').addClass('nav-up');
            } else {
                if (st < this.lastScrollTop && st < this.navbarHeight) {
                    $('header').removeClass('nav-up').addClass('nav-down');
                }
            }
            this.lastScrollTop = st;
        }
    }, {
        key: 'validateSelect',
        value: function validateSelect() {
            clearTimeout(this.yieldTo);
            $("select.villemoteur, select.villemoteur1").select2({
                matcher: function matcher(term, text) {
                    return text.toLowerCase().includes(term.split(' ').join('-').toLowerCase()) || text.split('-').join(' ').toLowerCase().includes(term.toLowerCase());
                },
                sortResults: function sortResults(results) {
                    return results;
                }
            });
        }
    }, {
        key: 'initMap',
        value: function initMap() {
            mapList = true;
            GoogleMap.init();
            $('.inner-prop').css('display', 'none');
        }
    }, {
        key: 'updateIndicators',
        value: function updateIndicators() {
            var DATA = [$('#property_search_nbRoom').val(), $('#property_search_nbBedRoom').val(), $('#property_search_surfaceMin').val(), $('#property_search_ref').val(), $('#property_search_nbBedding').val(), $('#property_search_beginAt').val(), $('#property_search_endingAt').val()],
                i,
                total = 0,
                more = $('a.more');
            for (i = 0; i < DATA.length; i++) {
                if (DATA[i] && DATA[i] != null) {
                    total++;
                }
            }
            total > 0 ? more.find('.indicator').text(total.toString()).css('display', 'block') : more.find('.indicator').css('display', 'none');
        }
    }, {
        key: 'updateSelect2',
        value: function updateSelect2($elements, $vals) {
            var i,
                len = $elements.length;
            for (i = 0; i < len; i++) {
                $elements[i].val($vals[i]).trigger('change');
            }
        }
    }, {
        key: 'upd',
        value: function upd(data) {
            this.$scope.data = data;
            var a = this.getKeys(this.$scope.data.ville);
            var b = this.getKeys(this.$scope.data.type);
            this.triggerFields(a, b);
            this.updateIndicators();
        }
    }, {
        key: 'getKeys',
        value: function getKeys(d) {
            var a = [];
            for (var key in d) {
                if (d.hasOwnProperty(key)) {
                    a.push(d[key]);
                }
            }
            return a;
        }
    }, {
        key: 'triggerFields',
        value: function triggerFields(a, b) {
            var i = void 0;
            $('#property_search_town').select2().val(a).trigger('change');
            $('#property_search_town1').select2().val(a).trigger('change');
            $('#property_search_type').select2().val(b).trigger('change');
            $('#property_search_type1').select2().val(b).trigger('change');
            $('#property_search_nbRoom').val(this.$scope.data.nbRoom);
            $('#property_search_nbBedRoom').val(this.$scope.data.nbBedRoom);
            for (i = 0; i < this.TRIGGERS.length; i++) {
                this.TRIGGERS[i].trigger('input');
            }
        }
    }, {
        key: 'resetForm',
        value: function resetForm() {
            var i = void 0;
            this.updateSelect2([$("select.villemoteur"), $("select.typemoteur"), $("select.villemoteur1"), $("select.typemoteur1")], [null, null, null, null]);
            $('#property_search_nbRoom').prop('selectedIndex', 0);
            $('#property_search_nbBedRoom').prop('selectedIndex', 0);
            for (i = 0; i < this.TRIGGERS.length; i++) {
                this.TRIGGERS[i].val('');
            }
            this.updateIndicators();
        }
    }, {
        key: 'prepToggle',
        value: function prepToggle(show) {
            if (show) {
                this.updateSelect2([$("select.villemoteur"), $("select.typemoteur")], [$("select.villemoteur1").val(), $("select.typemoteur1").val()]);
            } else {
                this.updateSelect2([$("select.villemoteur1"), $("select.typemoteur1")], [$("select.villemoteur").val(), $("select.typemoteur").val()]);
            }
            this.updateIndicators();
            this.toggleEngine();
        }
    }, {
        key: 'toggleEngine',
        value: function toggleEngine() {
            this.engine.toggleClass('is-visible');
            this.pageDim.toggleClass('is-visible');
        }
    }, {
        key: 'initLocal',
        value: function initLocal(lng, lat, add_marker) {
            var mapOptions = {
                center: new google.maps.LatLng(lng, lat),
                zoom: add_marker === 1 ? 16 : 14,
                scrollwheel: false,
                streetViewControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.TERRAIN]
                }
            };
            var map = new google.maps.Map(document.getElementById('carte_1'), mapOptions);
            if (add_marker === 1) {
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(lng, lat)
                });
                marker.setMap(map);
            }
            //GoogleMap.searchNearby(map); // Pour activer les POI
        }
    }]);

    return Universel;
}();
